body{
  font-family: 'Brother1816Regular';
  background-color: black;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MIS CSS */

.logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  padding: 20px 0px; 
 
}

.logo{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30pt;
  border: 1px solid white;
  color: white;
}

.header-container{
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  justify-content: flex-end;
  flex:1;
  background-color: white;
  height: 20vh;
  width: 100%;
}

.menu-container{
  display: flex;
  flex-direction: row;
  flex:1 1 auto;
}

.menu-item{
  padding: 0px 5px;
}

.body-container{
  background-color: black;
  display: flex;
  flex-direction: row;
  flex: 4;
  width: 100%;
}

nav a{
  color: black;
  text-decoration: none;
  font-size: small;
}

nav a.active{
  color: black;
  font-weight: bold;
}
