.store-container{
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 width: 45%;  
 color: white;
 font-family: 'Brother1816Regular';
 padding: 20px;
}

.store-name{
  font-size: 1.75em;
  text-transform: uppercase;

}

.store-phone, .store-web, .store-email{
  font-size: 0.75em;
}

.store-viewmap{
  font-size: 0.75em;
text-decoration: none;
color: red
}

a{
  text-decoration: none;
  color: red;
  font-size: 1em;
}