.home-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  flex:1
}

img{
  width: auto;
  max-width: 100%;
  object-fit:cover;
}

.text-container{
  display: flex;
  flex-direction: row;
  background-image: 
  url("../images/l_negro.png");
background-repeat: no-repeat;
background-position: left 30px bottom 5px;
}

.phrase{
  display: flex;
  flex:1;
  padding: 80px 10px;
}

.phrase > img{
  width: auto;
  max-width: 60%;
  object-fit:cover;
}

.description{
  display: flex;
  flex-direction: column;
  flex:1;
  color: white;
  size: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  padding-bottom: 20px;
}

.description > .title{
  padding: 20px 0px 0px 0px;
  font-size: 2.5rem;
  letter-spacing: 8px;
  font-family: 'Brother1816Regular';
}

.description > .subtitle{
  font-size: 1.1rem;
  letter-spacing: 8px;
}


.description > .description-text {
  line-height: 35px;
  text-align: start;
  padding: 25px 0px 0px 0px;
  font-family: 'Brother1816Regular';
}