.distributors-main-container{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.distributors-list-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items:flex-start;
    justify-content: space-between;
}
/*VIEJOS*/

.filters-container{
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(175, 139, 73);
}

.dropbox-container{
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(72, 192, 24);
}

.dropbox-label{
  padding: 5px;
  color: white;
}

.dropbox{
  padding: 5px;

}

.data-container{
  color: red;
}

