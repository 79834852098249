.about-container{
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  border: 0px solid rgb(37, 77, 165);
}

.image-container{
  display: flex;
  flex:2;
}

.description-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex:1;
  padding: 0px 30px;
  font-size: 1rem;
  
}

.description-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 80pt;
}