@font-face {
  font-family: 'Brother1816Regular';
  src: local('Brother1816Regular'),
       url('../fonts/Brother1816Printed-Regular.woff') format('woff'),
       url('../fonts/Brother1816Printed-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Brother1816Thin';
  src: local('Brother1816Thin'),
       url('../fonts/Brother1816-Thin.woff') format('woff'),
       url('../fonts/Brother1816-Thin.woff2') format('woff2');
}