.form-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  background-color: black;
  height: 75vh;
}

.text-container-contact{
  color: white;
  font-family: 'Brother1816Regular';
  padding-bottom: 20px;
}

.empty-column{
  display: flex;
  flex: 1;
}

.input-container{
  padding: 5px ;
}

.main-column{
  display: flex;
  flex-direction: column;
  flex:1;
  padding-right: 50px;
}

.contact-input{
  height: 25px;
  width: 100%;
}

textarea{
  width: 100%;
}

.contact-button{
  width: 100%;
  color: white;
  background-color: black;
  font-family: 'Brother1816Regular';
  font-size: 8pt;
}
.ok-message{
  color: green;
  background-color: black;
  font-family: 'Brother1816Regular';
  font-size: 20pt;
}

.main-container-contact{
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
}

.logos{

}